
import { Driver, Trip } from "@/types";
import { defineComponent } from "vue";

import InputSwitch from "primevue/inputswitch";
import { auth, db } from "@/main";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import DriverSelectCard from "@/components/driver-select-card.vue";
import emptyDashboardStatCard from "@/components/empty-dashboard-stat-card.vue";
import dashboardStatCard from "@/components/dashboard-stat-card.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "DriversView",
  components: {
    DataTable,
    Column,
    Dialog,
    emptyDashboardStatCard,
    DriverSelectCard,
    Calendar,
    dashboardStatCard,
    InputSwitch,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      show_paid: false,
      dates: [new Date("01/01/2018"), new Date()] as Date[] | null,
      payrollDialogVisible: false,
      modal_style: {
        display: "flex",
        maxHeight: "95%",
        width: "90%",
        padding: "24px",
        flexDirection: "column",
        borderRadius: "26px",
        background: "var(--bg-main, #fff)",
        boxShadow: "0px 3px 5px 0px rgba(93, 103, 128, 0.14)",
      },

      chipColorMap: [
        {
          type: "Reefer",
          color: "#89cff0",
        },
        {
          type: "Dry",
          color: "#90ee90",
        },
        {
          type: "Dry Van",
          color: "#90ee90",
        },
        {
          type: "Empty Miles",
          color: "#d3d3d3",
        },
        {
          type: "Layover",
          color: "#ffcc99",
        },
        {
          type: "Other",
          color: "#d3d3d3",
        },
      ],
    };
  },
  methods: {
    printDiv() {
      window.print();
    },
    openPayrollDialog() {
      this.payrollDialogVisible = true;
    },

    formatToUSD(value?: number | null) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value ?? 0);
    },
    formatTripDate(date: Date | undefined | null) {
      if (!date) return "";
      const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
      return formatter.format(date);
    },
    getChipColor(type: string) {
      const chip = this.chipColorMap.find((c) => c.type === type);
      if (chip) {
        return chip.color;
      }
      return "#d3d3d3";
    },
    trimAddress(address?: string) {
      // Regular expression to extract city, state, and zip code
      const addressRegex = /,\s*([\w\s]+),\s*([A-Z]{2})\s*(\d{5})$/;

      // Test if the address matches the expected format
      const match = address?.match(addressRegex);

      if (match) {
        // Extract city, state, and zip code
        const [, city, state, zip] = match;

        // Return the formatted part of the address
        return `${city.trim()}, ${state} ${zip}`;
      } else {
        // If the address doesn't match the format, return it as is
        return address;
      }
    },
  },
  computed: {
    drivers(): Driver[] | null {
      return this.store.state.drivers;
    },

    showPayrollDialog(): boolean {
      return (this.selected_driver !== undefined &&
        this.payrollDialogVisible) as boolean;
    },

    selected_driver_trips(): Trip[] {
      return this.store.state.selected_driver?.trips ?? [];
    },

    filtered_trips(): Trip[] {
      if (!this.selected_driver_trips) return [];
      if (!this.dates) return this.selected_driver_trips;
      let start = this.dates[0] ?? new Date("01/01/2018");
      let end = this.dates[1] ?? new Date();
      return this.selected_driver_trips.filter((trip) => {
        const trip_date = trip.trip_date ?? trip.pick_up_date;
        if (!trip_date) return false;
        //hide paid trips if show_paid is false
        if (!this.show_paid) {
          if (trip.driver_paid) return false;
        }

        return trip_date >= start && trip_date <= end;
      });
    },

    owed_to_selected_driver(): number {
      if (!this.filtered_trips) return 0;

      let total = 0;
      for (const trip of this.filtered_trips) {
        if (!this.show_paid) {
          if (!trip.driver_paid) {
            total += trip.total_payment;
          }
        } else {
          total += trip.total_payment;
        }
      }
      return total;
    },

    total_gross_income(): number {
      if (!this.filtered_trips) return 0;

      let total = 0;
      for (const trip of this.filtered_trips) {
        total += trip.gross_income;
      }
      return total;
    },

    unpaid_trips(): number {
      if (!this.filtered_trips) return 0;

      let total = 0;
      for (const trip of this.filtered_trips) {
        if (!trip.driver_paid) {
          total += 1;
        }
      }
      return total;
    },

    selected_driver(): Driver | null {
      return this.store.state.selected_driver;
    },

    total_net_income(): number {
      if (!this.filtered_trips) return 0;

      let total = 0;
      for (const trip of this.filtered_trips) {
        total += trip.net_income;
      }
      return total;
    },

    total_miles(): number {
      if (!this.filtered_trips) return 0;

      let total = 0;
      for (const trip of this.filtered_trips) {
        total += (trip.empty_miles ?? 0) + (trip.loaded_miles ?? 0);
      }
      return total;
    },
  },
});
