
import { Driver, Trip } from "@/types";

import { defineComponent } from "vue";
import { auth, db } from "@/main";
import {
  deleteDoc,
  doc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
//import Button from "primevue/button";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";

import dashboardStatCard from "@/components/dashboard-stat-card.vue";
import DriverSelectCard from "@/components/driver-select-card.vue";
import tripCalculator from "@/components/trip-calculator.vue";
import createDriver from "@/components/create-driver.vue";
import emptyDashboardStatCard from "@/components/empty-dashboard-stat-card.vue";
import tripEditDialog from "@/components/trip-edit-dialog.vue";
import InputSwitch from "primevue/inputswitch";

import { useStore } from 'vuex';

export default defineComponent({
  name: "DashboardView",
  components: {
    DataTable,
    Column,
    Dialog,
    emptyDashboardStatCard,
    dashboardStatCard,
    DriverSelectCard,
    tripCalculator,
    createDriver,
    tripEditDialog,
    InputSwitch,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      show_paid: false,
      editedTrip: {} as Trip,
      editDialogVisible: false,
      chipColorMap: [
        {
          type: "Reefer",
          color: "#89cff0",
        },
        {
          type: "Dry",
          color: "#90ee90",
        },
        {
          type: "Dry Van",
          color: "#90ee90",
        },
        {
          type: "Empty Miles",
          color: "#d3d3d3",
        },
        {
          type: "Layover",
          color: "#ffcc99",
        },
        {
          type: "Other",
          color: "#d3d3d3",
        },
      ],

      modal_style: {
        display: "flex",
        maxHeight: "95%",
        maxWidth: "800px",
        width: "90%",
        padding: "24px",
        flexDirection: "column",
        borderRadius: "26px",
        background: "var(--bg-main, #fff)",
        boxShadow: "0px 3px 5px 0px rgba(93, 103, 128, 0.14)",
      },

      selected_trip_to_edit: undefined as Trip | undefined,
    };
  },
  methods: {
    saveEditedTrip(trip?: Trip) {
      if (!trip) {
        console.log("no changes to save");
        return;
      }
      console.log("saving changes on trip", trip);
      //find trip in store by id on selected driver
      const tripIndex = this.selected_driver_trips.findIndex(
        (t) => t.id === trip.id
      );
      if (tripIndex === -1) {
        console.log("could not find trip in store");
        return;
      }

      trip.trip_date = new Date(trip.trip_date);
      trip.delivery_date = new Date(trip.delivery_date);

      //update trip in store
      this.selected_driver_trips[tripIndex] = trip;

      //update trip in db
      this.updateTripDoc(trip.id, trip);

      this.selected_trip_to_edit = undefined;
    },

    getChipColor(type: string) {
      const chip = this.chipColorMap.find((c) => c.type === type);
      if (chip) {
        return chip.color;
      }
      return "#d3d3d3";
    },

    async updateTripDoc(doc_id: string, new_trip: Trip) {
      if (!auth.currentUser || !auth.currentUser.uid) {
        console.log("no user. cannot update trip");
        return;
      }

      if (!this.store.state.selected_driver?.id) {
        console.log("no driver id. cannot update trip");
        return;
      }

      const tripRef = doc(
        db,
        `users/${auth.currentUser.uid}/drivers/${this.store.state.selected_driver.id}/trips/${doc_id}`
      );

      await updateDoc(tripRef, new_trip);
    },

    trimAddress(address?: string) {
      // Regular expression to extract city, state, and zip code
      const addressRegex = /,\s*([\w\s]+),\s*([A-Z]{2})\s*(\d{5})$/;

      // Test if the address matches the expected format
      const match = address?.match(addressRegex);

      if (match) {
        // Extract city, state, and zip code
        const [, city, state, zip] = match;

        // Return the formatted part of the address
        return `${city.trim()}, ${state} ${zip}`;
      } else {
        // If the address doesn't match the format, return it as is
        return address;
      }
    },
    formatToUSD(value?: number | null) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value ?? 0);
    },
    formatTripDate(date: Date | undefined) {
      if (!date) return "";
      const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
      return formatter.format(date);
    },

    openEditTripDialog() {
      this.editDialogVisible = true;
    },

    openAddTripDialog() {
      this.store.state.trip_calculator_visible = true;
    },

    deleteTrip(trip: Trip) {
      if (!auth.currentUser || !auth.currentUser.uid) {
        console.log("no user. cannot delete trip");
        return;
      }

      if (!this.store.state.selected_driver?.id) {
        console.log("no driver id. cannot delete trip");
        return;
      }

      const tripRef = doc(
        db,
        `users/${auth.currentUser.uid}/drivers/${this.store.state.selected_driver.id}/trips/${trip.id}`
      );

      //delete trip from db
      deleteDoc(tripRef);

      //delete trip from store
      const tripIndex = this.selected_driver_trips.findIndex(
        (t) => t.id === trip.id
      );
      if (tripIndex === -1) {
        console.log("could not find trip in store");
        return;
      }
      this.selected_driver_trips.splice(tripIndex, 1);
    },
  },

  computed: {
    drivers(): Driver[] | null {
      return this.store.state.drivers;
    },

    owed_to_selected_driver(): number {
      if (!this.selected_driver_trips) return 0;

      let total = 0;
      for (const trip of this.selected_driver_trips) {
        if (!this.show_paid) {
          if (!trip.driver_paid) {
            total += trip.total_payment;
          }
        } else {
          total += trip.total_payment;
        }
      }
      return total;
    },

    total_gross_income(): number {
      if (!this.selected_driver_trips) return 0;

      let total = 0;
      for (const trip of this.selected_driver_trips) {
        total += trip.gross_income;
      }
      return total;
    },

    unpaid_trips(): number {
      if (!this.selected_driver_trips) return 0;

      let total = 0;
      for (const trip of this.selected_driver_trips) {
        if (!trip.driver_paid) {
          total += 1;
        }
      }
      return total;
    },

    selected_driver_trips(): Trip[] {
      const trips = this.store.state.selected_driver?.trips ?? [];
      if (!this.show_paid) {
        return trips.filter(trip => !trip.driver_paid);
      }
      return trips;
    },

    total_net_income(): number {
      if (!this.selected_driver_trips) return 0;

      let total = 0;
      for (const trip of this.selected_driver_trips) {
        total += trip.net_income;
      }
      return total;
    },

    total_miles(): number {
      if (!this.selected_driver_trips) return 0;

      let total = 0;
      for (const trip of this.selected_driver_trips) {
        total += (trip.empty_miles ?? 0) + (trip.loaded_miles ?? 0);
      }
      return total;
    },
  },
});
